@use "colors" as *;
@use "sizes" as *;
@use "mixins/reset" as *;

.key-indicators {
  display: flex;
  gap: 16px;
}

.key-indicator {
  align-items: flex-start;
  border: 1px solid $color-stroke-light;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: space-between;
  max-height: 71px;
  padding: 12px;

  &__value {
    @include reset-paragraph;

    font-weight: 700;
  }
}
