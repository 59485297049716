.milestone {
  &__header {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__timeline {
    margin-top: 1rem;
    width: 50%;
  }
}
