@use "colors" as *;

.title {
  align-items: center;
  display: flex;
  gap: 8px;
}

.icon {
  color: $color-typo-secondary;
  height: 1.143rem;
  width: 1.143rem;
}
