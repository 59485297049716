@use "sizes" as *;
@use "mixins/breakpoints" as *;

.container {
  align-items: flex-end;

  // TODO: Temporary display:none for mobile devices
  display: none;
  flex-direction: column;

  @include desktop-viewport {
    display: flex;
  }
}
