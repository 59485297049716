.form {
  margin-top: 2rem;
}

.key-indicators {
  margin-top: .5rem;
}

.marginBottom {
  margin-bottom: 1rem;
}
