@use "colors" as *;

.stats-line {
  background-color: $color-neutral-3;
  height: 100%;
  transition: filter .2s cubic-bezier(.445, .05, .55, .95);
  width: 0;

  &:not(:first-of-type)::before {
    background-color: $color-white;
    content: "";
    display: block;
    height: 100%;
    width: 2px;
  }

  &:hover {
    filter: brightness(110%);
  }
}

.standalone {
  border-radius: 2px;
}
