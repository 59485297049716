@use "mixins/breakpoints" as *;
@use "mixins/reset" as *;
@use "colors" as *;

.hamburger-button {
  @include reset-button;

  border-radius: 50%;
  cursor: pointer;
  flex: 0 0 32px;
  min-height: 32px;
  padding: 7px;
  transform: translateX(-7px);
  transition: all .2s cubic-bezier(.445, .05, .55, .95);

  &--pressed {
    background-color: $color-background-dark;
  }

  &--icon {
    display: block;
    height: 100%;
    width: 100%;

    & path {
      transform-origin: center center;
      transition: all .2s cubic-bezier(.445, .05, .55, .95);
    }

    &__selected path {
      &:first-of-type {
        transform: rotate(45deg) translateY(7px);
      }

      &:nth-of-type(2) {
        transform: rotate(-45deg);
      }

      &:nth-of-type(3) {
        transform: scaleX(0);
      }
    }
  }
}
