@use "mixins/reset" as *;
@use "mixins/underline-active-indicator" as *;
@use "colors" as *;
@use "sizes" as *;

.container {
  height: 4rem;
}

.list {
  @include reset-list;

  display: flex;
  height: 100%;

  &__item {
    padding-top: .25em;
  }
}

.tab {
  @include reset-button;

  align-items: center;
  color: $color-dove-gray;
  cursor: pointer;
  display: flex;
  font-size: .875em;
  font-weight: 700;
  height: 100%;
  justify-content: center;
  margin: 0 1em;
  position: relative;

  &:hover {
    color: $color-mine-shaft;
  }

  &--active {
    color: $color-mine-shaft;
  }
}

.active-indicator {
  @include underline-active-indicator;

  &--transition {
    transition: transform .25s;
  }
}
