@use "colors" as *;
@use "mixins/reset" as *;

.section {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  gap: 12px;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 0;
  padding: 0 0 0 18px;

  &--container {
    background-color: $color-background-light;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 12px;
  }
}

.caption {
  color: $color-neutral-1;
}

.button {
  background: unset;
  border: unset;
  margin-left: 12px;
  padding: 0;
  width: fit-content;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.no-comments {
  color: $color-neutral-1;
}
