@use "colors" as *;
@use "mixins/reset" as *;
@use "mixins/hide" as *;

.progress {
  display: flex;

  &__text {
    margin-bottom: .125rem;
  }

  &__icon {
    color: $color-neutral-2;
    height: 16px;
    width: 16px;
  }

  &__content {
    @include reset-paragraph;

    align-items: center;
    display: flex;
    gap: .365rem;
    justify-content: space-between;
  }

  &__label {
    @include hide-visually;
  }

  &__bar-container {
    background-color: rgba($color-primary, .2);
    border-radius: 2px;
    height: 4px;
    margin-top: .365rem;
    position: relative;
  }

  &__bar {
    background-color: $color-primary;
    border-radius: 2px;
    inset: 0;
    position: absolute;
  }
}

.container {
  width: 100%;
}
