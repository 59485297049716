@use "colors" as *;
@use "sizes" as *;
@use "mixins/reset" as *;

.actions {
  align-items: flex-start;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}

.heading {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.no-recent-insights-message {
  font-weight: 700;
  margin: 2rem 0 0;
  padding: 0 $size-layout-content-padding;
}

.milestone-statuses {
  margin-top: 12px;
}

.key-indicators {
  display: flex;
  gap: 16px;
}

.recent-insights {
  padding: 0 $size-layout-content-padding;
}
