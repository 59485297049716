@use "colors" as *;
@use "sizes" as *;

.content {
  padding-top: 20px;
}

.top-section {
  margin-bottom: 20px;
}

.buttons {
  align-items: center;
  display: flex;
  justify-content: center;
}

.button {
  & ~ & {
    margin-left: 1rem;
  }

  &__menu {
    height: 2.571rem;
    padding-left: .393rem;
    width: 2.571rem;
  }
}

.description {
  color: $color-typo-secondary;
  margin-bottom: 32px;
}

.heading-extra {
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: center;
}
