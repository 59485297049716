@use "mixins/reset" as *;
@use "mixins/underline-active-indicator" as *;
@use "mixins/breakpoints" as *;
@use "colors" as *;
@use "sizes" as *;

.container {
  border-bottom: 1px solid $color-stroke-light;
  height: 40px;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  scrollbar-width: none;

  @include phone-viewport {
    &::after {
      background-color: $color-white;
      bottom: 2px;
      box-shadow: 0 0 20px 10px $color-white;
      content: "";
      display: block;
      height: 40px;
      position: absolute;
      right: -20px;
      width: 20px;
    }
  }

  @include desktop-viewport {
    &::after {
      display: none;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.indicator {
  @include underline-active-indicator;

  bottom: 0;
  transition: all .25s;
}

.line {
  background-color: $color-primary;
  border-radius: 2px;
  height: 2px;
  transform: translateY(4px);
  transition: transform .3s cubic-bezier(.445, .05, .55, .95);
  width: 100%;
}

.nav-link {
  @include reset-anchor;

  border-radius: 6px;
  color: $color-typo-primary;
  cursor: pointer;
  font-size: 1rem;
  padding: 0 6px;
  position: relative;

  &--active {
    position: relative;

    & + .line {
      transform: translateY(0);
    }
  }

  &__content {
    align-items: center;
    display: flex;
    gap: 4px;
    justify-content: center;
    margin: 2.5px 6px;
    white-space: nowrap;
  }
}

.list {
  @include reset-list;

  align-items: flex-end;
  display: flex;
  gap: 20px;
  height: 100%;
  justify-content: flex-start;
  position: relative;

  &--item {
    align-self: center;
    display: flex;
    flex-direction: column;
    gap: 10.5px;
    height: 100%;
    justify-content: flex-end;

    &::before {
      display: none;
    }

    &:hover .nav-link {
      background-color: $color-hover-light;
    }
  }
}

.icon {
  flex: 1 0 14px;
}
