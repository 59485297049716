@use "colors" as *;

.header {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: space-between;
}

.heading-section {
  align-items: center;
  display: flex;
  justify-content: space-between;
  min-height: 3.125rem;
}

.heading {
  margin: 0;
}

.users-stats {
  align-items: center;
  background-color: $color-background-light;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  gap: 40px;
  justify-content: space-between;
  padding: 32px 40px;

  &--text {
    max-width: 480px;
  }

  &--chart {
    flex-basis: 460px;
    margin-left: auto;
  }
}

.controls {
  display: flex;
  gap: 20px;
}

:export {
  atRiskColor: $color-alert;
  blockedColor: $color-error;
  doneColor: $color-primary;
  noStatusColor: $color-neutral-2;
  onTrackColor: $color-success;
}
