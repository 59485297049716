// todo: remove all free usages of colors other than those defined under THEME COLORS
$color-porcelain: #f0f2f4;
$color-ghost-white: #f8f9f9;
$color-alabaster: #f7f7f7;
$color-gallery: #ededed;
$color-platinum: #e1e4ea;
$color-mercury: #e1e5ea;
$color-light-gray: #cfd2d8;
$color-athens-gray: #e1e3e9;
$color-alto: #e0e0e0;
$color-iron: #d8d8d9;
$color-silver: #c2c2c2;
$color-quick-silver: #a3a6ab;
$color-ash-gray: #b3b8be;
$color-aluminium: #a9afb7;
$color-nickel: #6c727b;
$color-boulder: #7a7a7a;
$color-dove-gray: #666;
$color-mine-shaft: #1f1f1f;

$color-navy-blue: #080066;
$color-blue-ribbon: #015ef4;
$color-malibu: #89aaff;
$color-anakiwa: #aecdfe;
$color-hawkes-blue: #e7f0ff;
$color-egg-white: #fff0bf;

$color-magic-mint: #9effcb;
$color-caribbean-green: #02ca7f;
$color-blond: #ffedba;
$color-lightning-yellow: #fbc122;
$color-pastel-orange: #f6ab51;
$color-carnation: #f9606a;
$color-mandy: #ed4f59;
$color-misty-rose: #ffe6e7;
$color-bridesmaid: #feedef;

// THEME COLORS

$color-white: #fff;
$color-black: #000;

$color-typo-primary: $color-black;
$color-typo-secondary: $color-nickel;

$color-neutral-1: $color-nickel;
$color-neutral-2: $color-aluminium;
$color-neutral-3: $color-light-gray;

$color-primary: $color-blue-ribbon;
$color-primary-variant: $color-hawkes-blue;
$color-secondary: $color-navy-blue;
$color-secondary-variant: $color-pastel-orange;

$color-success: $color-caribbean-green;
$color-success-light: $color-magic-mint;
$color-alert: $color-lightning-yellow;
$color-alert-light: $color-blond;
$color-error: $color-mandy;
$color-error-light: $color-misty-rose;

$color-stroke-light: $color-platinum;
$color-stroke-medium: $color-light-gray;
$color-stroke-dark: $color-quick-silver;

$color-hover-light: $color-ghost-white;
$color-hover-dark: $color-mercury;

$color-background-dark: $color-porcelain;
$color-background-light: $color-ghost-white;
