@use "colors" as *;

.theme-category {
  align-items: center;
  background-color: $color-primary;
  border-radius: .5rem;
  color: $color-white;
  display: flex;
  font-weight: 700;
  height: 3rem;
  justify-content: center;
  padding: 0 .75rem;
}

.themes {
  margin-top: 2rem;
}

.vision-name {
  display: block;
  margin-bottom: 2rem;
  margin-top: -1rem;
}
