@use "colors" as *;
@use "mixins/reset" as *;

.select-overlay-trigger {
  @include reset-button;

  background: $color-white;
  border: 1px solid $color-stroke-dark;
  border-radius: 12px;
  color: $color-black;
  font-size: 14px;
  user-select: none;
  white-space: nowrap;

  &:hover {
    background-color: $color-hover-light;
  }

  &__icon {
    height: .625em;
    width: .625em;

    &--medium {
      height: 1em;
      width: 1em;
    }

    &--big {
      height: 1.4em;
      width: 1.4em;
    }

    &--color-secondary {
      color: $color-typo-secondary;
    }
  }

  &--size-small {
    height: 2.5rem;
    padding: .5rem;
  }

  &--size-medium {
    height: 3rem;
    padding: .75rem;
  }

  &--full-width {
    width: 100%;
  }

  &--expanded {
    border-color: $color-black;
  }

  &--disabled {
    background-color: $color-background-light;
    border-color: $color-stroke-light;
    color: $color-neutral-2 !important;
    cursor: default;
  }
}
