@use "colors" as *;

.team-card-descriptions-item {
  border: 1px solid transparent;
  border-radius: 6px;
  color: $color-white;
  cursor: pointer;
  padding: .25rem .5rem;
  transition: border .2s;

  &:hover {
    border: 1px solid rgba($color-white, .8);
  }
}
