@use "colors" as *;

.tree-list-row {
  $tree-list-row: &;

  transition: .2s background-color;

  &:hover {
    background: $color-background-light;
  }

  &__row-content-container {
    padding: .5rem 1.25rem;
  }

  &__direct-child-items-container {
    position: relative;

    &::before {
      border-left: 1px solid $color-neutral-3;
      bottom: 0;
      content: "";
      left: 2.5rem;
      position: absolute;
      top: 0;
      width: 1px;
      z-index: 2;
    }
  }

  &--expanded {
    background-color: $color-background-light;
  }

  &--top-level {
    position: relative;

    &:last-of-type {
      #{$tree-list-row}__row-content-container {
        padding-bottom: 1rem;
      }
    }
  }

  &--clickable {
    cursor: pointer;
  }

  &:not(&--top-level) {
    margin-left: 4rem;
  }

  &--selected {
    border: 5px solid red;
    #{$tree-list-row}__row-content-container {
      background-color: $color-background-dark !important;
    }
  }
}
