@use "colors" as *;

.table-view {
  overflow: hidden;

  &__table {
    border-collapse: collapse;
    border-style: hidden;
    color: $color-typo-secondary;
    width: 100%;
  }

  &__before-container,
  &__after-container {
    padding: 1.25rem 1.75rem;
  }

  &__before-container {
    padding-top: 1.75rem;
  }

  &__after-container {
    padding-bottom: 1.75rem;
  }

  &--with-border {
    border: 1px solid $color-stroke-medium;
    border-radius: 1.25rem;
  }

  &--with-top-padding {
    padding-top: .75rem;
  }

  &--with-bottom-padding {
    padding-bottom: .75rem;
  }
}
