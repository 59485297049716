@use "../Roadmap.module" as *;

$size-container-width: 24px;
$size-background-tile-height: 16px;

.container {
  background-clip: content-box;
  background-image: url("./static/flexible-tile-background.svg?inline");
  background-repeat: repeat-y;
  flex: 1;
  position: relative;
  width: 24px;

  &--hidden {
    background-image: none;
  }

  &--column-2 {
    margin-left: $size-column-2 - $size-container-width;
  }

  &--column-3 {
    margin-left: $size-column-3 - $size-container-width;
  }

  &--column-specified {
    align-self: flex-start;
  }

  &--cut-on-top {
    border-top: 10px solid transparent;
  }

  &--cut-on-bottom {
    border-bottom: 10px solid transparent;
  }
}

.content {
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  z-index: 100;

  &--cut-on-top {
    bottom: 5px;
  }

  &--cut-on-bottom {
    top: 5px;
  }
}

:export {
  backgroundTileHeightSize: $size-background-tile-height;
}
