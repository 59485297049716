@use "mixins/reset" as *;
@use "colors" as *;

.list {
  @include reset-list;

  &--ordered {
    list-style-type: decimal;
  }

  &--unordered {
    list-style-type: disc;
  }

  &--no-marker {
    list-style-type: none;
  }

  &--regular {
    color: $color-dove-gray;
  }

  &--emphasis {
    color: $color-mine-shaft;
  }

  &--gutter-size-none {
    padding-left: 0;
  }

  &--gutter-size-small {
    padding-left: .5rem;
  }

  &--gutter-size-regular {
    padding-left: 1rem;
  }

  &--gutter-size-medium {
    padding-left: 1.2rem;
  }

  &--gutter-size-large {
    padding-left: 1.5rem;
  }
}
