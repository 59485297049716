@use "colors" as *;

.descriptions-item {
  display: flex;
  gap: 8px;

  &__label {
    color: $color-typo-secondary;
  }
}
