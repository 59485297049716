@use "colors" as *;

.column {
  display: flex;
  flex: 1 0 calc(33% - 80px);
  flex-flow: column wrap;
  gap: 20px;
  padding: 0;

  &--header-border {
    border-radius: 2px;
    height: 2px;
    width: 100%;

    &__active {
      background-color: $color-success;
    }

    &__inactive {
      background-color: $color-ash-gray;
    }

    &__done {
      background-color: $color-primary;
    }
  }
}

.heading {
  margin: 0 0 12px;
  text-transform: uppercase;

  &--nrOfActions {
    color: $color-nickel;
  }
}

.empty {
  display: flex;
  justify-content: center;
}

.more-cards-button {
  transition: all .3s cubic-bezier(0, .7, .3, 1);

  & div {
    color: $color-black;

    &:hover {
      text-decoration: underline;
    }
  }

  &:hover {
    box-shadow: 0 4px 20px 0 rgb(0 0 0 / 12%);
  }
}
