@use "colors" as *;

.toast {
  align-items: center;
  background: $color-white;
  background-position: 1rem;
  background-repeat: no-repeat;
  border-radius: 12px;
  box-shadow: rgba($color-black, .1) 0 0 .5rem;
  color: $color-white;
  display: flex;
  font-size: 1.125rem;
  gap: .5rem;
  justify-content: flex-start;
  padding: .75rem;

  &__icon {
    height: 1.25rem;
    width: 1.25rem;
  }

  &--success {
    background-color: $color-success;
  }

  &--error {
    background-color: $color-error;
  }

  &--info {
    color: $color-black;
  }
}
