@use "colors" as *;

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 24px;
  margin-top: 44px;
}

.icon {
  align-items: center;
  background-color: $color-background-dark;
  border-radius: 35px;
  display: flex;
  font-size: 2.143rem;
  height: 70px;
  justify-content: center;
  width: 70px;
}

.title {
  font-size: 1.5rem;
  font-weight: 700;
}

.description {
  color: $color-typo-secondary;
  margin-bottom: 15px;
  text-align: center;
  white-space: pre-line;
}
