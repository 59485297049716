@use "colors" as *;
@use "mixins/hide" as *;

.checkbox {
  $checkbox: &;

  display: inline-block;

  &__label {
    align-items: center;
    cursor: pointer;
    display: flex;
    gap: .8rem;
    justify-content: center;
    user-select: none;
  }

  &__input {
    @include hide-visually;
  }

  &__indicator {
    background-clip: content-box;
    border: 2px solid $color-silver;
    border-radius: .188rem;
    flex-shrink: 0;
    height: 1rem;
    position: relative;
    transition-duration: .2s;
    transition-property: border, background-color;
    width: 1rem;
  }

  &__icon-container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    position: relative;
    width: 100%;
  }

  &__icon {
    color: $color-white;
    height: 1.2rem;
    inset: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    width: 1.2rem;
  }

  &--checked {
    #{$checkbox}__indicator {
      background-color: $color-black;
      border-color: $color-black;
    }
  }

  &--focused {
    #{$checkbox}__indicator {
      border-color: $color-black;
    }
  }
}
