@use "colors" as *;
@use "sizes" as *;
@use "mixins/breakpoints" as *;

.background {
  background-attachment: local;
  background-position: top center;
  background-size: 177%;
}

.card {
  align-items: center;
  background-color: $color-white;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgb(0 0 0 / 12%);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
}
