@use "colors" as *;

.container {
  border: 1px solid $color-light-gray;
  border-radius: 20px;
  overflow: hidden;
}

.tree-list {
  margin-bottom: -1px;
  margin-top: -1px;
}

.controls {
  margin-bottom: 1.5rem;
}
