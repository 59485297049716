@use "colors" as *;
@use "mixins/breakpoints" as *;

.container {
  border: 1px solid $color-light-gray;
  border-radius: 20px;
  overflow: hidden;
}

.controls {
  justify-content: flex-start;
  margin-top: 2rem;

  @include tablet-viewport {
    justify-content: flex-end;
    margin-top: 0;
  }
}

.tree-list-container {
  padding: 1.5rem;
}

.tree-list {
  margin-bottom: -1px;
}

.no-team {
  margin-bottom: 32px;
}
