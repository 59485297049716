@use "colors" as *;
@use "fonts" as *;

$size-track-height: 4px;

.slider {
  max-width: 100%;
  padding: 1rem 12px 2rem;

  :global {
    /* stylelint-disable declaration-no-important */
    /* stylelint-disable max-nesting-depth */
    /* stylelint-disable selector-max-compound-selectors */
    .rc-slider-rail {
      background-color: $color-alto !important;
      height: $size-track-height;
    }

    .rc-slider-track {
      background-color: $color-primary !important;
      height: $size-track-height;
    }

    .rc-slider-mark-text {
      color: $color-dove-gray;
      font-family: $font-family-sofia-pro;
      font-size: 1rem;
      margin-top: 5px;
    }

    .rc-slider-handle {
      background-color: $color-primary;
      border: 2px solid $color-white;
      height: 16px;
      margin-top: -6px;
      opacity: 1;
      transition: box-shadow .2s;
      width: 16px;

      &:active {
        border: 2px solid $color-white;
        box-shadow: 0 0 0 6px rgba(#f0f0f0, .5);
      }
    }

    .rc-slider-handle-dragging {
      border: 2px solid $color-white !important;
      box-shadow: 0 0 0 10px rgba($color-primary, .2) !important;
    }

    .rc-slider-dot {
      background: transparent;
      border: 0;
    }

    .rc-slider-step {
      display: grid;
      grid-auto-flow: column;
    }

    .rc-slider-disabled {
      background: none;
      opacity: .5;

      .rc-slider-handle {
        background-color: $color-alto !important;
      }

      .rc-slider-track {
        background-color: $color-silver !important;
      }
    }
    /* stylelint-enable declaration-no-important */
    /* stylelint-enable max-nesting-depth */
    /* stylelint-enable selector-max-compound-selectors */
  }
}
