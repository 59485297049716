@use "colors" as *;

.container {
  margin-left: 2.5rem;
  padding-bottom: 2rem;
  will-change: transform;

  h3 {
    margin-top: 0;
  }
}

.selection {
  border: 1px solid $color-stroke-medium;
  border-radius: 20px;
  gap: 0;
}
