.description {
  display: block;
  max-width: 80%;
}

.initiative-status {
  align-items: center;
  justify-content: flex-start;
  margin-top: 2rem;
}
