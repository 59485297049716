@use "colors" as *;

.header {
  align-items: baseline;
  display: flex;
  justify-content: space-between;
}

.link {
  color: $color-primary;
  text-decoration: none;

  &:visited,
  &:active,
  &:hover {
    color: $color-primary;
    text-decoration: underline;
  }
}
