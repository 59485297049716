@use "sizes" as *;

.container {
  bottom: 0;
  left: 50%;
  margin: 0 auto;
  max-width: $size-layout-content-max-width;
  padding: 1rem;
  position: absolute;
  transform: translateX(-50%);
  z-index: 8000;
}
