@use "mixins/reset" as *;
@use "colors" as *;

.steps-button {
  $step-button: &;

  @include reset-button;

  align-items: center;
  color: $color-mine-shaft;
  cursor: pointer;
  display: flex;
  gap: .25rem;
  justify-content: center;

  &:active {
    color: $color-mine-shaft;
  }

  &__name {
    font-size: .875rem;
    font-weight: 700;
    white-space: nowrap;
  }

  &--active {
    #{$step-button}__name {
      color: $color-primary;
    }
  }

  &--completed {
    #{$step-button}__icon {
      color: $color-success;
    }
  }

  &--error {
    #{$step-button}__icon {
      color: $color-error;
    }
  }

  &--in-progress {
    #{$step-button}__icon {
      color: $color-dove-gray;
    }
  }

  &--disabled {
    color: $color-dove-gray;
    cursor: default;

    &:active {
      color: $color-dove-gray !important;
    }
  }

  &--not-ready {
    color: $color-dove-gray;
    cursor: default;
  }
}
