@use "colors" as *;

.container {
  background-color: $color-alabaster;
  border-radius: 1rem;
  padding: 1.25rem;
}

.caption {
  padding: .5rem 0;
}

.status-comments-container {
  padding: 1rem;
}

.status-comments {
  margin-top: -1.5rem;
}

.vision-name {
  display: block;
  margin-bottom: 2.5rem;
}
