@use "colors" as *;

@mixin underline-active-indicator {
  background: $color-blue-ribbon;
  border-radius: 5px 5px 0 0;
  content: "";
  height: 3px;
  position: absolute;
  width: 2.5em;
}
