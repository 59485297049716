@use "colors" as *;

.container {
  background-image: url("./static/pin-tile-background.svg?inline");
  background-repeat: no-repeat;
  border-radius: 50%;
  box-shadow: 0 0 1rem 1rem rgba($color-black, .05);
  height: 56px;
  position: relative;
  width: 56px;
}
