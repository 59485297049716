@use "colors" as *;
@use "sizes" as *;

.content {
  padding-top: 20px;
}

.main-container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.top-section {
  margin-bottom: 20px;
}

.buttons {
  align-items: center;
  display: flex;
  justify-content: center;
}

.button {
  & ~ & {
    margin-left: 1rem;
  }

  &__menu {
    height: 2.571rem;
    padding-left: .393rem;
    width: 2.571rem;
  }
}

.description {
  color: $color-dove-gray;
  margin: 0;
  margin-bottom: 32px;
}

.heading-extra {
  align-items: center;
  display: flex;
  flex-grow: 1;
  gap: 1rem;
  justify-content: center;
}

.above-heading-container {
  margin-bottom: 1rem;
}
