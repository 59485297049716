@use "colors" as *;

@mixin base-modal-container {
  height: 100vh;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 5000;
}

@mixin default-modal-container {
  @include base-modal-container;

  background-color: rgba($color-black, .5);
}
