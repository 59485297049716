@use "colors" as *;
@use "fonts" as *;

.text {
  font-family: $font-family-sofia-pro;
  font-style: normal;
  font-weight: 400;
  white-space: pre-wrap;

  &--regular {
    color: $color-typo-secondary;
  }

  &--light {
    color: $color-silver;
  }

  &--emphasis {
    color: $color-typo-primary;
  }

  &--strong {
    color: $color-typo-primary;
    font-weight: 700;
  }

  &--note {
    background-color: $color-alto;
    border-radius: 12px;
    color: $color-mine-shaft;
    font-weight: 700;
    letter-spacing: .2px;
    padding: 2px 6px;
    text-transform: uppercase;
  }

  &--size-medium {
    font-size: 1rem;
  }

  &--size-small {
    font-size: .9rem;
  }

  &--size-tiny {
    font-size: .75rem;
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--italic {
    font-style: italic;
  }

  &--bold {
    font-weight: 700;
  }

  &--centered {
    display: block;
    text-align: center;
  }

  &--size-big {
    font-size: 1.1rem;
  }

  &--size-large {
    font-size: 1.25rem;
  }

  &--color-primary {
    color: $color-primary;
  }

  &--color-black {
    color: $color-black;
  }

  &--color-error {
    color: $color-error;
  }

  &--single-line-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &--multi-line-ellipsis {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
