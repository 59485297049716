@use "colors" as *;
@use "mixins/reset" as *;

.section {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.list {
  display: "flex";
  flex-direction: column;
  gap: 4px;
  list-style-type: disc;
  margin: 0;
  padding-left: 13px;
}

.caption {
  color: $color-typo-secondary !important;
}

.no-comments {
  color: $color-neutral-1;
}
