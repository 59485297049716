.container {
  display: flex;
}

.label {
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 2px;
}

.value {
  align-items: center;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: flex-end;
  margin: auto;

  &--single-digit {
    min-width: 9px;
  }

  &--double-digit {
    min-width: 18px;
  }

  &--triple-digit {
    min-width: 22px;
  }
}
