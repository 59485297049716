.form {
  margin-top: 2rem;
}

.button {
  margin-top: -.5rem;
}

.marginBottom {
  margin-bottom: 1rem;
}
