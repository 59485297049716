@use "colors" as *;

.layout {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-bottom: 12px;
}

.header {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: space-between;
  padding: 24px;
}

.heading-section {
  align-items: center;
  display: flex;
  justify-content: space-between;
  min-height: 3.125rem;
}

.heading {
  margin: 0;
  padding: 34px 24px 22px;
}
