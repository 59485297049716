@use "mixins/reset" as *;
@use "colors" as *;

.list-view {
  @include reset-list;

  border: 1px solid $color-stroke-medium;
  border-radius: 1.25rem;
  overflow: hidden;

  &__before-container,
  &__after-container {
    padding: .625rem 1.25rem;
  }
}
