@use "mixins/reset" as *;
@use "mixins/underline-active-indicator" as *;
@use "colors" as *;
@use "sizes" as *;

.tab {
  @include reset-anchor;

  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: .875em;
  font-weight: 700;
  height: 100%;
  justify-content: center;
  margin: 0 1em;
  position: relative;
}

.active-indicator {
  @include underline-active-indicator;

  &__transition {
    transition: transform .25s;
  }
}

.list {
  @include reset-list;

  border-bottom: 1px solid $color-athens-gray;
  display: flex;
  gap: 2rem;
  height: 3.75rem;
  margin: 2.5rem 0;
  position: relative;

  &__item {
    cursor: pointer;
    padding-bottom: .714rem;
    padding-top: 1.563rem;
  }
}

.button {
  $step-button: &;

  @include reset-button;

  color: $color-dove-gray;
  font-weight: 900;
  height: 100%;
  justify-content: center;
  padding: 0;

  &:hover {
    color: $color-mine-shaft;
  }

  &__active {
    color: $color-mine-shaft;
  }
}
