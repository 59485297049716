@use "mixins/breakpoints" as *;

.container {
  align-items: center;
  flex-direction: column !important;
  height: 25rem;
  justify-content: space-between;
  padding: 0 2rem;

  @include tablet-viewport {
    align-items: flex-start;
    flex-direction: row !important;
    height: 16rem;
  }
}

.heading {
  margin-top: 2rem;
}

.summary {
  display: block;
  max-width: 18rem;
}

.chart-container {
  height: 16rem;
  overflow: hidden;
  width: 22rem;
}

.chart-wrapper {
  margin-top: -3rem;
}

.chart {
  width: 308px;
}
