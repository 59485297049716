@use "colors" as *;

.list {
  align-items: stretch;
  display: flex;
  justify-content: flex-start;
  list-style-type: none;
  margin: 0;
  padding: 0;

  &--direction {
    &__column {
      flex-direction: column;
    }

    &__row {
      flex-direction: row;
    }
  }
}

.section {
  &:last-of-type {
    & li:last-of-type::after {
      border-bottom: none;
    }
  }
}

.header {
  align-items: center;
  cursor: pointer;
  display: flex;

  &--padding {
    padding: 20px 24px 20px 26px;
  }

  &:hover {
    background-color: $color-hover-light;
  }
}

.actions-button {
  align-items: center;
  color: $color-neutral-1;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  margin-left: auto;

  &--icon {
    transition: all .2s cubic-bezier(.445, .05, .55, .95);
    width: .67rem;

    &__expanded {
      transform: rotateX(180deg);
    }
  }
}
