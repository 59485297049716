@use "mixins/modal" as *;
@use "colors" as *;

.container {
  @include default-modal-container;

  align-items: center;
  display: flex;
  justify-content: center;
}

.modal {
  background-color: $color-white;
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 640px;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
  padding: 32px 32px 24px;
  padding-bottom: 24px;

  & .icon {
    height: unset;
    padding: 0;
    position: absolute;
    right: 20px;
    top: 20px;

    svg {
      height: 1.429rem;
      width: 1.429rem;
    }
  }
}

.heading {
  margin: 0;
}

.description {
  color: $color-typo-secondary;
  margin: 0 0 4px;
}

.form {
  border-bottom: 1px solid $color-stroke-medium;
  padding: 0 32px 12px;
}

.timeline {
  align-items: center;
  display: flex;
  gap: 10px;

  &--icon {
    color: $color-typo-secondary;
    display: inline-block;
    flex-basis: 1.714rem;
    min-height: 1.714rem;
    min-width: 1.714rem;
  }

  &--text {
    display: inline-block;
    white-space: nowrap;
  }
}

.metadata {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 12px;
  padding-bottom: 32px;
}

.user {
  align-items: center;

  & span {
    color: $color-typo-secondary;
  }
}

.buttons {
  display: flex;
  gap: 12px;
  padding: 20px 32px 24px;
}

.button {
  & div {
    padding-top: 0;
  }

  &--secondary div {
    color: $color-black;
  }

  &--update {
    margin-left: auto;
  }
}

.menu {
  padding: 0;

  & > div {
    padding: 12px 0;
  }

  & span {
    margin-top: 0;
    padding: 0;
  }

  & li > span {
    align-items: center;
    display: flex;
    gap: 12px;
    padding-left: 20px;
  }
}

.menu-item {
  align-items: center;
  display: flex;
  gap: 16px;
  justify-content: flex-start;
}

.completion-rate {
  display: flex;
  gap: 36px;

  &--text-input {
    flex: 0 0 48px;
    max-width: 48px;
    padding-right: 2px;
    width: unset !important;
  }

  &--slider-input {
    flex: 1 1 100%;
  }

  & > div:first-of-type {
    width: unset !important;
  }
}

.audit-record {
  color: $color-ash-gray;
  justify-content: space-between;

  &--text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &__last-update {
      flex-shrink: 0;
    }
  }
}

.menu-icon {
  color: $color-nickel;
  height: 1rem;
  width: 1rem;
}

.overdue {
  color: $color-error;
}
