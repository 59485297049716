@import url("https://fonts.googleapis.com/css2?family=Noto+Color+Emoji&display=swap");

@font-face {
  font-family: "Sofia Pro";
  font-weight: 400;
  src: url("../static/fonts/sofia-pro-regular.woff2") format("woff2");
}

@font-face {
  font-family: "Sofia Pro";
  font-weight: 700;
  src: url("../static/fonts/sofia-pro-bold.woff2") format("woff2");
}

$font-family-sofia-pro: "Sofia Pro", sans-serif;
