@use "sizes" as *;

$size-column-2: 228px;
$size-column-3: 352px;
$size-column-5: 520px;
$size-column-6: 680px;

$size-spacer-small: 170px;
$size-spacer-medium: 300px;

$size-content-small: 350px;
$size-content-medium: 510px;

.container {
  max-width: $size-layout-content-max-width;
}

:export {
  column2Size: $size-column-2;
  column3Size: $size-column-3;
  column5Size: $size-column-5;
  column6Size: $size-column-6;
  contentMediumSize: $size-content-medium;
  contentSmallSize: $size-content-small;
  spacerMediumSize: $size-spacer-medium;
  spacerSmallSize: $size-spacer-small;
}
