@use "colors" as *;

.objective-chip {
  background-color: $color-white;
  border: 1px solid $color-silver;
  border-radius: .5rem;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 2.25rem 1rem 1.25rem;

  &__name {
    color: $color-mine-shaft;
  }

  &__current-objective-status {
    margin-left: .25rem;
    margin-top: .5rem;
  }

  &--size-big {
    height: 5rem;
  }
}
