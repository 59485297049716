@use "mixins/hide" as *;

.container {
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: stretch;
}

.backgroundContainer {
  background: url("static/background-sign-up.svg");
  background-color: #f7fbfe;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  flex: 1;
  max-width: 60vh;
  position: relative;

  .logo {
    bottom: 60px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
  }
}

.contentContainer {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: 0 120px;

  .content {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 500px;
  }

  .contentHeading {
    text-align: center;
  }

  .contentDescription {
    font-size: 18px;
    text-align: center;
  }
}
