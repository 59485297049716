.divider {
  transform: translateX(1.25rem);
  transition: all .2s cubic-bezier(.445, .05, .55, .95);
  width: calc(100% - 2.5rem);
  will-change: transform, width;

  &--expanded {
    transform: unset;
    width: 100%;
  }
}
