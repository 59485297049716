@use "colors" as *;

.header {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: space-between;
  padding: 24px;
}

.heading-section {
  align-items: center;
  display: flex;
  justify-content: space-between;
  min-height: 3.125rem;
}

.heading {
  margin: 0;
}

.users-stats {
  align-items: center;
  background-color: $color-background-light;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  gap: 40px;
  justify-content: space-between;
  min-height: 100px;
  padding: 32px 40px;

  &--text {
    max-width: 230px;
  }

  &--active-chart {
    flex-basis: 460px;
  }

  &--overdue-chart {
    margin-left: auto;
  }
}

.controls {
  display: flex;
  gap: 20px;
}

.filters {
  width: 100%;
}

.filters-container {
  width: 100%;
}

.toggle-and-filters-container {
  align-items: center;
  width: 100%;
}

.sorters-and-filters-container {
  justify-content: space-between;
  width: 100%;
}

:export {
  activeColor: $color-success;
  doneColor: $color-primary;
  inactiveColor: $color-neutral-2;
  overdueColor: $color-error;
}
