@use "colors" as *;

@keyframes underlay-background {
  0% {
    opacity: 0;
  }

  100% {
    background: rgba($color-black, .5);
    opacity: 1;
  }
}

.underlay {
  animation: .2s cubic-bezier(.445, .05, .55, .95) 0s 1 underlay-background;
  background-color: rgba($color-black, .5);
  inset: 0;
  position: fixed;
  z-index: 2;
}
