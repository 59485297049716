@use "colors" as *;

.tag {
  border: 1px solid transparent;
  border-radius: 4px;
  display: inline-block;
  font-size: .725em;
  font-weight: 800;
  height: 2.5em;
  padding: 0 .75em;
  text-transform: uppercase;

  &__content {
    align-items: center;
    display: flex;
    gap: .25em;
    height: 100%;
    justify-content: center;
    white-space: nowrap;
    width: 100%;
  }

  &__icon {
    height: 1.5em;
    margin-left: -.125em;
    width: 1.5em;
  }

  &--size-tiny {
    align-self: center;
    font-size: .625rem;
    padding: 0 .25em;
  }

  &--size-small {
    align-self: center;
    font-size: .675rem;
    padding: 0 .5em;
  }

  &--variant-primary {
    background-color: $color-primary-variant;
    border-color: $color-primary-variant;
    color: $color-primary;
  }

  &--variant-secondary {
    background-color: $color-white;
    border-color: $color-stroke-light;
    color: $color-black;
  }

  &--variant-ghost {
    background-color: $color-neutral-2;
    border-color: $color-neutral-2;
    color: $color-white;
  }

  &--variant-success {
    background-color: $color-success;
    border-color: $color-success;
    color: $color-white;
  }

  &--variant-alert {
    background-color: $color-alert;
    border-color: $color-alert;
    color: $color-white;
  }

  &--variant-danger {
    background-color: $color-error-light;
    border-color: $color-error-light;
    color: $color-error;
  }
}

:export {
  colorForDarkBackground: $color-white;
  colorForLightBackground: $color-black;
}
