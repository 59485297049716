@use "colors" as *;

.container {
  align-items: center;
  background-color: $color-background-light;
  border-radius: 8px;
  display: flex;
  flex: 1 1 50%;
  flex-direction: column;
  gap: 4px;
  height: 76px;
  padding: 12px;
}

.label {
  color: $color-typo-secondary;
  line-height: 1.429rem;
}

.value {
  color: $color-typo-primary;
  font-size: 1.429rem;
  font-weight: 700;
  line-height: 2rem;
}
