$size-layout-content-max-width: 1200px;
$size-layout-content-padding: 2.5rem;

$phone-breakpoint: 640px;
$tablet-breakpoint: 768px;
$desktop-breakpoint: 1280px;

$phone-margin: 20px;
$tablet-margin: 40px;
$min-desktop-margin: 40px;
