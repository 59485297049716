@use "colors" as *;

.container {
  background-color: $color-white;
  border-radius: 1.25rem;
  padding-bottom: 2.5rem;
  padding-left: 2.5rem;
}

.heading {
  margin-top: 0;
}
