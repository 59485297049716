.modal {
  width: 33.75rem;

  &__controls {
    flex: 1;
    justify-content: space-between;
  }
}

.form {
  height: 15rem;
}
