@use "colors" as *;

.circle-spinner {
  $circle-spinner: &;

  align-items: center;
  display: flex;
  justify-content: center;
  visibility: hidden;

  &__circle {
    animation-duration: .75s;
    animation-iteration-count: infinite;
    animation-name: rotate-circle;
    animation-timing-function: linear;
    border: 1px solid $color-primary;
    border-radius: 50%;
    border-right-color: transparent;
  }

  &--centered {
    inset: 0;
    position: absolute;
  }

  &--displayed {
    visibility: visible;
  }

  &--size-small {
    #{$circle-spinner}__circle {
      border-width: 2px;
      height: 1rem;
      width: 1rem;
    }
  }

  &--size-medium {
    #{$circle-spinner}__circle {
      border-width: 3px;
      height: 1.75rem;
      width: 1.75rem;
    }
  }
}

.container {
  &--size-medium {
    height: 20rem;
  }

  &--size-small {
    height: 10rem;
  }
}

@keyframes rotate-circle {
  100% {
    transform: rotate(360deg);
  }
}
