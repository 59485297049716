@use "colors" as *;

.divider {
  border: none;
  border-bottom: 1px solid $color-athens-gray;
  height: 1px;
  width: 100%;

  &--no-margin {
    margin: 0;
  }
}
