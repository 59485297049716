.space {
  display: flex;

  &--tiny {
    gap: .25em;
  }

  &--small {
    gap: .5em;
  }

  &--medium {
    gap: 1em;
  }

  &--big {
    gap: 1.5em;
  }

  &--large {
    gap: 2em;
  }

  &--wrap {
    flex-wrap: wrap;
  }

  &--centered {
    align-items: center;
    justify-content: center;
  }

  &--vertical {
    flex-direction: column;
  }

  &--horizontal {
    flex-direction: row;
  }
}
