.toggle-expand-icon {
  cursor: pointer;
  height: 16px;
  margin-left: 1rem;
  margin-right: 1rem;
  width: 16px;
}

.heading-container {
  justify-content: space-between;
}

.heading {
  cursor: pointer;
  margin-bottom: 2rem;
}

.date {
  font-size: .875rem;
  width: 10rem;
}

.comments-container {
  margin-top: 1rem;
}
