@use "colors" as *;

.container {
  align-items: center;
  display: flex;
}

.heading {
  font-weight: 700;
  margin: 0 8px 0 12px;
}

.primary {
  color: $color-secondary-variant;
}
