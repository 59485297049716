@use "colors" as *;

.section {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.caption {
  color: $color-typo-secondary !important;
}

.no-comment {
  color: $color-neutral-1;
}
