@use "colors" as *;
@use "sizes" as *;
@use "mixins/reset" as *;
@use "mixins/breakpoints" as *;

.container {
  background-color: $color-white;
  border-bottom: 1px solid $color-stroke-light;
  display: flex;
  height: 60px;
  justify-content: center;
  overflow: hidden;
  scrollbar-gutter: stable;
}

.inner-container {
  align-items: center;
  display: flex;
  gap: 40px;
  justify-content: space-between;
  max-width: 1200px;
  padding-left: $phone-margin;
  padding-right: $phone-margin;
  width: 100%;

  @include tablet-viewport {
    margin-left: $tablet-margin;
    margin-right: $tablet-margin;
    padding: 0;
  }
}

.link-list {
  @include reset-list;

  display: none;
  gap: 32px;

  @include tablet-viewport {
    display: flex;
  }
}

.avatar-and-select {
  align-items: center;
  display: flex;

  @include tablet-viewport {
    gap: 16px;
  }
}

.org-select {
  display: none;
  max-width: 230px;

  @include tablet-viewport {
    display: block;
  }

  &--mobile {
    height: 40px;
    width: 100%;
  }
}

.hamburger-menu-button {
  @include tablet-viewport {
    display: none;
  }
}
