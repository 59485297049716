@use "colors" as *;

.details {
  align-items: center;
  flex-grow: 1;
  user-select: none;
}

.team-stats {
  align-items: center;
  display: flex;
  font-size: .875rem;
  gap: .5em;
}

.bullet {
  color: $color-neutral-2;
}

.link-icon {
  height: 1em;
  width: 1em;
}

.menu {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}
