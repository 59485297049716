@use "colors" as *;
@use "mixins/breakpoints" as *;

.container {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(1, minmax(250px, 1fr));
  margin-top: 1.5rem;

  @include tablet-viewport {
    grid-template-columns: repeat(2, minmax(250px, 1fr));
  }

  @include desktop-viewport {
    grid-template-columns: repeat(3, minmax(250px, 1fr));
  }
}
