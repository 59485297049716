@use "colors" as *;

.menu-row-container {
  cursor: pointer;
  outline: none; // remove react-aria outline
  padding: .625rem 1rem;

  &--focused {
    background-color: $color-hover-light !important;
  }

  &--disabled {
    cursor: default;
    opacity: .3;
  }
}
