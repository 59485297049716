@use "colors" as *;

.emoji-badge {
  align-items: center;
  background-color: $color-egg-white;
  border-radius: 50%;
  display: flex;
  height: 2.5rem;
  justify-content: center;
  width: 2.5rem;

  &__content {
    font-size: 1.5em;
  }
}
