@use "colors" as *;
@use "mixins/reset" as *;

.text-button {
  $text-button: &;

  @include reset-button;

  color: $color-primary;
  font-size: 1rem;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  &:disabled {
    color: $color-neutral-3;
  }

  &__icon {
    height: 1.2rem;
    width: 1.2rem;
  }

  &--variant-strong {
    font-weight: 700;
  }

  &--icon-placement-start {
    #{$text-button}__icon-container {
      margin-right: .5rem;
    }
  }

  &--icon-placement-end {
    #{$text-button}__icon-container {
      margin-left: .5rem;
    }
  }
}
