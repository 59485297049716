@use "colors" as *;

.name-col {
  align-items: center;
  display: flex;
  gap: 8px;

  svg {
    color: $color-neutral-2;
    height: 16px;
    width: 16px;
  }
}

.owner-col {
  color: $color-typo-secondary;
  display: flex;
  gap: 8px;
}

.status-col {
  color: $color-neutral-2;
}

.updated-col {
  color: $color-neutral-2;
  place-self: end;

  .updated {
    color: $color-primary;
  }

  .completed {
    color: $color-primary;
  }

  .pending {
    color: $color-typo-secondary;
  }
}
