@use "colors" as *;

.container {
  border-top: 1px solid $color-platinum;
  display: flex;
  justify-content: center;
}

.content {
  color: $color-nickel;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
