@use "colors" as *;

.container {
  display: flex;
  flex-direction: column;
  gap: 11.5px;
}

.status-container {
  align-items: center;
  display: flex;
  flex: 0 0 40px;
  gap: 8px;
  padding-top: 4.5px;
}

.main-container {
  align-items: flex-start;
  display: flex;
  flex: 1 0 100%;
}

.name-container {
  align-items: flex-start;
  display: flex;
  flex: 0 1 452px;
  gap: 12px;
  margin-right: 28px;

  &.is-clickable {
    cursor: pointer;
  }
}

.name {
  margin: 0;
}

.description-container {
  margin-left: 40px;
}

.description {
  color: $color-typo-secondary;
  margin: 0;
  white-space: pre-line;
}

.date {
  color: $color-typo-secondary;
  flex-basis: 96px;
  margin-left: auto;
  padding-right: 28px;
}

.milestone-icon {
  color: $color-neutral-2;
  height: 13px;
  width: 13px;
}

.dropdown-icon {
  flex: 0 0 14px;
  height: 12px;
  position: relative;
  top: 4.5px;
  transition: all .2s cubic-bezier(.445, .05, .55, .95);

  &.is-expanded {
    transform: rotate(180deg);
  }
}
