@use "colors" as *;

.container {
  --left: 0;

  background-color: $color-background-dark;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  gap: 4px;
  padding: 4px;
  position: relative;
  width: fit-content;
  z-index: 1;
}

.button {
  align-items: center;
  display: flex;
  height: 40px;
  justify-content: center;
  transition: all .2s cubic-bezier(.445, .05, .55, .95);
  width: 40px;
  z-index: 3;

  &:focus {
    opacity: unset;
  }
}

.active {
  color: $color-black;
}

.inactive {
  color: $color-neutral-1;
}

.active-background {
  background-color: $color-white;
  border-radius: 8px;
  height: 40px;
  left: 4px;
  position: absolute;
  transform: translateX(var(--left));
  transition: all .2s cubic-bezier(.445, .05, .55, .95);
  width: 40px;
  z-index: 2;
}
