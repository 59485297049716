.list-item {
  &--size-big {
    padding: .5rem 0;

    &:first-of-type {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
}
