@use "colors" as *;

.header {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: space-between;
  padding: 24px;
}

.heading-section {
  align-items: center;
  display: flex;
  justify-content: space-between;
  min-height: 3.125rem;
}

.heading {
  margin: 0;
}

.controls {
  display: flex;
  gap: 20px;
}
