@use "colors" as *;
@use "sizes" as *;

$size-height-expanded: 31.25rem;

.container {
  background-color: $color-primary;
  height: $size-height-expanded;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.content {
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  margin: 0 auto;
  max-width: $size-layout-content-max-width;
  padding-bottom: 2.5rem;
  padding-left: 20rem;
}

.heading {
  color: $color-white;
}

.text {
  color: $color-white;
  opacity: .5;
}

.spacer {
  height: $size-height-expanded;
}

.report-stage-tag {
  opacity: .6;
}
