@use "colors" as *;

.nav {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  margin-top: .5rem;
  overflow-x: visible;
  padding-bottom: .5rem;
}

.tabs {
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 2rem;
  width: calc(100% + 1rem);
}

.section {
  &:not(:last-child) {
    margin-bottom: 2rem;
  }
}

.select-space > div {
  flex: 1;
}

.content {
  padding: 2rem;
}

.heading {
  display: inline-block;
  margin-bottom: 0;
  margin-left: .5rem;
  margin-top: 0;

  &--container {
    align-items: center;
    display: flex;
    margin-bottom: 1rem;
  }
}

.description {
  display: block;
  margin-bottom: .5rem;
}

.authored {
  align-items: flex-end;
  justify-content: space-between;
}

.icon {
  color: $color-primary;
  display: inline-block;
  flex-shrink: 0;
  height: 1.5rem;
  margin-left: -.5rem;
  width: 1.5rem;

  &--tips-tricks {
    color: $color-primary;
  }
}
