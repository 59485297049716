@use "colors" as *;

.status-mark {
  $status-mark: &;

  align-items: center;
  border-radius: 50%;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  position: relative;

  &__inside {
    border-radius: 50%;
    height: 100%;
    width: 100%;

    &--with-background {
      background-color: $color-silver;
    }
  }

  &--border {
    background-color: rgba($color-silver, .3);

    #{$status-mark}__inside {
      height: 55%;
      width: 55%;
    }
  }

  &--small {
    height: .5rem;
    width: .5rem;

    #{$status-mark}--border {
      height: .875em;
      width: .875em;
    }

    &#{$status-mark}--icon {
      height: .625rem;
      width: .625rem;
    }
  }

  &--medium {
    height: .625em;
    width: .625em;

    &#{$status-mark}--border {
      height: 1.125em;
      width: 1.125em;
    }

    &#{$status-mark}--icon {
      height: .8rem;
      width: .8rem;
    }
  }

  &--icon {
    background: none !important;
  }
}
