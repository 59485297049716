@use "colors" as *;

.icon {
  --completion-degree: 0deg;

  background:
    conic-gradient(
      $color-primary 0deg var(--completion-degree),
      $color-light-gray var(--completion-degree) 360deg
    );
  border-radius: 100%;
  display: block;
  height: 1.143rem;
  position: relative;
  width: 1.143rem;

  &::after {
    background-color: $color-white;
    border-radius: 50%;
    content: "";
    display: block;
    height: 12px;
    left: 2px;
    position: absolute;
    top: 2px;
    width: 12px;
  }
}
