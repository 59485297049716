@use "colors" as *;

.monitor {
  display: flex;
  padding: 20px;

  .title {
    display: flex;
    gap: 5px;
  }

  .time {
    align-items: center;
    display: flex;
    flex-grow: 1;
    gap: 4px;
    justify-content: flex-end;
    text-align: right;

    .label {
      margin-right: 8px;
    }

    svg {
      color: $color-neutral-2;
    }
  }
}

.preview {
  border-top: 1px solid $color-stroke-light;
  padding: 20px;
}

.notifiedMessage {
  display: block;
  margin-bottom: 20px;
}

.table {
  column-gap: 24px;
  display: grid;
  grid-template-columns: 1fr auto auto auto;
  row-gap: 20px;
}
