@use "colors" as *;

.container {
  align-items: center;
  border: 1px solid $color-athens-gray;
  border-radius: 1rem;
  flex: 1;
  max-width: 100%;
  padding: 1rem;
}

.text {
  flex-basis: 50%;
}

.statusMarks {
  flex-basis: 50%;
}
