@use "colors" as *;

.row {
  align-items: center;
  display: flex;
  gap: 12px;
  min-height: 48px;
  padding: 0 1px 0 3.5px;

  &:not(:last-of-type) {
    border-bottom: 1px solid $color-stroke-light;
  }

  &--clickable {
    cursor: pointer;
  }
}

.sub-title {
  color: $color-neutral-2;
}

.icon {
  color: $color-neutral-2;
  flex-shrink: 0;
  height: 16px;
  width: 16px;
}

.date {
  color: $color-neutral-1;
}

.toggle-icon {
  margin-right: .5rem;
  overflow: hidden;
  padding: 0;
  width: 1rem;
}

.chevron-icon {
  margin: -3px 2px 0 8px;
  width: 12px;
}
