@use "colors" as *;
@use "mixins/reset" as *;

.list-box {
  background: $color-white;
  border: 1px solid $color-stroke-medium;
  border-radius: 8px;
  box-shadow: 0 0 12px 8px rgba($color-black, .06);
  max-height: inherit;
  min-height: unset;
  overflow-x: hidden;
  overflow-y: auto;

  &__list {
    @include reset-list;
  }
}
