@use "mixins/breakpoints" as *;

.team-card {
  flex: 1;
  max-width: 100%;

  @include tablet-viewport {
    max-width: 50%;
  }
}
