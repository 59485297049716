@use "colors" as *;

.theme {
  align-items: center;
  cursor: pointer;
  gap: 8px;
  padding: 15px 20px;
  user-select: none;
}

.objective {
  &__details {
    align-items: center;
    display: flex;
    gap: 8px;
    padding: 15px 20px;

    button {
      height: 16px;
    }

    svg {
      height: 16px;
      width: 16px;
    }
  }

  &__name {
    align-items: center;
    cursor: pointer;
    display: flex;
    user-select: none;
  }

  &__expand-button {
    height: 25px;
  }

  &__items {
    display: flex;
    flex-direction: column;

    svg {
      height: 16px;
      width: 16px;
    }
  }

  &__item {
    align-items: center;
    cursor: pointer;
    gap: 8px;
    padding: 15px 20px 15px 40px;
    user-select: none;
  }
}

.element-icon {
  color: $color-neutral-2;
}

.row-end {
  color: $color-typo-secondary;
  display: flex;
  flex-grow: 1;
  gap: 12px;
  justify-content: flex-end;
  white-space: nowrap;
}

.owner-details {
  align-items: center;
  display: flex;
  gap: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 200px;
}

.element-status {
  width: 120px;
}
