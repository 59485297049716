@use "colors" as *;

.heading {
  &__icon {
    color: $color-dove-gray;
    height: 1em;
    visibility: hidden;
    width: 1em;

    &--visible {
      visibility: visible;
    }
  }
}
