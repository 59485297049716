@use "colors" as *;
@use "mixins/reset" as *;

.link {
  @include reset-anchor;

  color: $color-typo-secondary;
  font-size: .875em;
  font-weight: 700;
  transition: all .2s cubic-bezier(.445, .05, .55, .95);
  white-space: pre;

  &:hover {
    color: $color-mine-shaft;
    cursor: pointer;
  }

  &--active {
    color: $color-typo-primary;
  }
}
