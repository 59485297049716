@use "sizes" as *;
@use "mixins/reset" as *;
@use "colors" as *;

.container {
  padding: $size-layout-content-padding;
}

.actions-container {
  align-items: center;
  justify-content: space-between;
}

.no-related-objectives-message {
  font-weight: 700;
  margin: 0;
}

.objective-list {
  margin-top: -1rem;
}

.layout {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-bottom: 20px;
}
