@use "colors" as *;

.card {
  padding: 24px;
}

.description {
  color: $color-typo-secondary;
  max-width: 370px;
  text-align: center;
}
