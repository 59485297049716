@use "mixins/reset" as *;
@use "mixins/hide" as *;

.dropdown-button {
  @include reset-button;

  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 0;
  transition: transform .2s;

  &__icon {
    height: 1em;
    width: 1em;
  }

  &__content {
    @include hide-visually;
  }

  &--active {
    transform: scaleY(-1);
  }
}
