@use "colors" as *;

.teamCardDetailsName {
  color: $color-white;
  cursor: pointer;
}

.teamCardDetailsContent {
  min-width: 0;
}
