@use "colors" as *;

.emphasis {
  color: $color-typo-primary;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.header {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 16px;

  &:hover .emphasis:first-of-type {
    text-decoration: underline;
  }
}

.short-text {
  color: $color-typo-secondary;
  overflow: hidden;
  white-space: nowrap;
}

.status-indicator {
  align-items: center;
  display: flex;
  flex: 0 1;
  gap: 6px;

  &--name {
    white-space: nowrap;
  }
}

.dropdown-button {
  padding: 0;
}
