@use "sizes" as *;

@mixin phone-viewport {
  @media (min-width: $phone-breakpoint) {
    @content;
  }
}

@mixin tablet-viewport {
  @media (min-width: $tablet-breakpoint) {
    @content;
  }
}

@mixin desktop-viewport {
  @media (min-width: $desktop-breakpoint) {
    @content;
  }
}
