@use "colors" as *;

.container {
  background-color: $color-white;
  border: 1px solid $color-stroke-light;
  border-radius: 8px;
  display: flex;
  font-size: .857rem;
  gap: 7px;
  padding: 10px;
}

.label {
  color: $color-typo-primary;
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap;
}
