@use "colors" as *;

.container {
  align-items: center;
  display: flex;
  justify-content: center;

  &.containerBackground {
    background-color: $color-white;
  }

  &.containerGlobal {
    z-index: 5000;
  }

  &.containerCentered {
    inset: 0;
    position: absolute;
  }
}

.bricks {
  animation-delay: .3s;
  animation-direction: alternate;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-name: show-bricks;
  background-color: $color-white;
  height: 4rem;
  opacity: 0;
  position: relative;
  transition-duration: .3s;
  transition-property: opacity;
  width: 4rem;
}

.brick {
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  background-clip: content-box;
  border-radius: 20%;
  box-sizing: border-box;
  height: 50%;
  padding: 6%;
  position: absolute;
  width: 50%;
}

.brick1 {
  animation-delay: .3s;
  animation-name: move-brick-1;
  background-color: #0249b7;
}

.brick2 {
  animation-delay: .15s;
  animation-name: move-brick-2;
  background-color: #3286ff;
}

.brick3 {
  animation-name: move-brick-3;
  background-color: #fbc123;
}

@keyframes move-brick-1 {
  from {
    transform: translate(100%, 100%);
  }

  8% {
    transform: translate(0, 100%);
  }

  25% {
    transform: translate(0, 100%);
  }

  33% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(0, 0);
  }

  58% {
    transform: translate(100%, 0);
  }

  75% {
    transform: translate(100%, 0);
  }

  83% {
    transform: translate(100%, 100%);
  }

  to {
    transform: translate(100%, 100%);
  }
}

@keyframes move-brick-2 {
  from {
    transform: translate(0, 100%);
  }

  8% {
    transform: translate(0, 0);
  }

  25% {
    transform: translate(0, 0);
  }

  33% {
    transform: translate(100%, 0);
  }

  50% {
    transform: translate(100%, 0);
  }

  58% {
    transform: translate(100%, 100%);
  }

  75% {
    transform: translate(100%, 100%);
  }

  83% {
    transform: translate(0, 100%);
  }

  to {
    transform: translate(0, 100%);
  }
}

@keyframes move-brick-3 {
  from {
    transform: translate(0, 0);
  }

  8% {
    transform: translate(100%, 0);
  }

  25% {
    transform: translate(100%, 0);
  }

  33% {
    transform: translate(100%, 100%);
  }

  50% {
    transform: translate(100%, 100%);
  }

  58% {
    transform: translate(0, 100%);
  }

  75% {
    transform: translate(0, 100%);
  }

  83% {
    transform: translate(0, 0);
  }

  to {
    transform: translate(0, 0);
  }
}

@keyframes show-bricks {
  from {
    opacity: 0;
  }

  5% {
    opacity: 1;
  }

  to {
    opacity: 1;
  }
}
