@use "colors" as *;

@keyframes move-arrow {
  from {
    transform: translateX(-2px);
  }

  to {
    transform: translateX(4px);
  }
}

.top-section {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.nav-link {
  $nav-link: &;

  cursor: pointer;
  display: flex;
  white-space: nowrap;

  &:hover {
    & #{$nav-link}--icon {
      animation-direction: alternate;
      animation-duration: .5s;
      animation-iteration-count: infinite;
      animation-name: move-arrow;
    }
  }

  &--icon {
    transition: transform .2s cubic-bezier(.445, .05, .55, .95);

    &__container {
      align-items: center;
      display: flex;
      flex: 1 0 14px;
      justify-content: center;
    }
  }
}

.link {
  color: $color-typo-primary;
  display: flex;
  font-size: .857rem;
  gap: 10px;
  justify-content: flex-end;
  text-decoration: none;
}
