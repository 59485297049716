@use "colors" as *;

.table-view-row {
  $row: &;

  border-top: 1px solid transparent;
  height: 4rem;
  overflow: hidden;

  &:first-of-type {
    #{$row}__cell-content {
      border-top: 1px solid transparent;
    }
  }

  &:not(:hover) {
    &:not(:first-of-type) {
      #{$row}__cell-content {
        border-top: 1px solid $color-stroke-light;
      }
    }
  }

  // stylelint-disable no-descending-specificity
  &:hover {
    background-color: $color-hover-light;

    &:not(:first-of-type) {
      border-top: 1px solid $color-stroke-light;
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid $color-stroke-light;
    }
  }
  // stylelint-enable no-descending-specificity

  &__cell-content-container {
    align-items: center;
    display: flex;
    height: 4rem;
    justify-content: center;
    margin: -1px;
  }

  &__cell-content {
    align-items: center;
    border-top: 1px solid transparent;
    display: flex;
    flex: 1;
    height: 100%;
    margin-bottom: 2px;
    padding: .5rem;
  }

  &__cell-spacer {
    height: 100%;
    width: 1.25rem;
  }
}
