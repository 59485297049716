@use "mixins/hide" as *;
@use "colors" as *;

.radio-card {
  align-items: center;
  background-color: $color-white;
  border: 1px solid $color-silver;
  border-radius: .75em;
  cursor: pointer;
  display: flex;
  font-size: 1em;
  gap: .5em;
  justify-content: center;
  padding: 1em .75em;
  transition: .2s background-color;
  user-select: none;

  &__input {
    @include hide-visually;
  }

  &--error {
    background-color: rgba($color-error, .1);
    border-color: $color-error;
  }

  &--checked {
    background-color: rgba($color-anakiwa, .2);
    border-color: $color-primary;
  }
}
