@use "mixins/breakpoints" as *;

.chart-and-operational-items-container {
  flex: 1;
}

.themes-and-operational-items-container {
  flex-direction: column-reverse !important;

  @include desktop-viewport {
    flex-direction: row !important;
  }
}
