@use "colors" as *;

.drawer {
  $drawer: &;

  background-color: rgba($color-black, .5);

  &--left {
    justify-content: flex-start !important;
  }

  &--right {
    justify-content: flex-end !important;
  }

  &--small {
    #{$drawer}__modal {
      max-width: 23.625rem;
    }
  }

  &--medium {
    #{$drawer}__modal {
      max-width: 46rem;
    }
  }
}
