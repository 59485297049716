@use "sizes" as *;

.no-milestones {
  font-weight: 700;
  margin: 2rem 0 0;
  padding: 0 $size-layout-content-padding;
}

.description {
  white-space: pre-wrap;
}
