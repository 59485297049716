.scroll-spy-container {
  flex-shrink: 0;
  width: 20rem;
}

.scroll-spy {
  padding-top: 4rem;
  position: sticky;
  top: 1px;
}

.content {
  display: flex;
  flex: 1;
  padding-bottom: 6rem;
}

.content-inside {
  flex: 1;
  overflow: hidden;
}
