@use "colors" as *;

.context-menu-row {
  $context-menu-row: &;

  align-items: center;
  min-width: 13.75rem;

  &__icon {
    height: 1.125rem;
    width: 1.125rem;
  }

  &__text {
    font-size: 1rem;
  }

  &--variant-danger {
    color: $color-error;

    #{$context-menu-row}__text {
      color: $color-error;
    }
  }
}
