@use "colors" as *;

.layout {
  padding: 24px 0;
}

.timeline {
  margin-top: 1.5rem;
}

.actions {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.activityTimeline {
  padding: 0 24px;
}
