@use "colors" as *;

.chart {
  max-height: 400px;
  min-height: 290px;
}

:export {
  chartLegendColor: $color-mine-shaft;
  doughnutBackgroundColor: $color-background-dark;
  doughnutSubTitleColor: $color-boulder;
  doughnutTitleColor: $color-mine-shaft;
}
