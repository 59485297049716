@use "mixins/reset" as *;
@use "colors" as *;

.context-menu-trigger {
  @include reset-button;

  align-items: center;
  background-color: $color-white;
  border-radius: .75rem;
  cursor: pointer;
  display: flex;
  height: 3rem;
  justify-content: center;
  transition: all .2s cubic-bezier(.445, .05, .55, .95);
  width: 3rem;

  &:hover {
    opacity: .8;
  }

  &__icon {
    height: 1.5rem;
    width: 1.5rem;
  }
}
