@use "colors" as *;

.container {
  align-items: flex-start;
}

.form-card {
  flex: 1;
}

.info-card {
  width: 24rem;
}

.form {
  margin-top: 2rem;
}
