@use "colors" as *;
@use "mixins/reset" as *;

.details {
  align-items: center;
  height: 100%;
  padding-left: 1rem;
  position: relative;
  user-select: none;
  width: 100%;
  z-index: 1;
}

.header {
  align-items: center;
  width: 100%;
}

.highlight {
  background-color: $color-background-dark;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.child-objectives {
  align-items: center;
  display: flex;
  font-size: .875rem;
  gap: .5em;
}

.row {
  justify-content: space-between;
}

.metadata {
  color: $color-typo-secondary;
  flex-grow: 1;
  justify-content: flex-end;
}

.date {
  align-items: center;
  white-space: nowrap;

  &--icon {
    display: inline-block;
    flex-basis: 14px;
    margin-right: 6px;
    min-height: 14px;
    min-width: 14px;
  }
}

.team-icon {
  text-decoration: none;
}

.link-icon {
  height: 1em;
  width: 1em;
}
