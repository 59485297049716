.item {
  &__header {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  &__create-button {
    margin-top: 3rem;
  }

  &:first-of-type {
    margin-top: -1rem;
  }

  & ~ & {
    margin-top: 2rem;
  }
}
