@use "mixins/breakpoints" as *;
@use "mixins/reset" as *;
@use "sizes" as *;
@use "colors" as *;

.mobile-menu {
  @include reset-list;

  background-color: $color-white;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-left: -29px;
  outline: none;
  padding: 12px 20px 24px;
  position: fixed;
  width: 100%;

  @include tablet-viewport {
    margin-left: -49px;
    padding-left: $tablet-margin;
    padding-right: $tablet-margin;
  }
}
