@use "colors" as *;

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 0;
  padding: 0;
}

.sections {
  align-items: stretch;
  display: flex;
  gap: 20px;
}

.section {
  display: flex;
  flex-basis: 0;
  flex-direction: column;
  flex-grow: 1;
  gap: 12px;
  overflow: hidden;
}

.caption {
  color: $color-neutral-1;
}
