@use "mixins/reset" as *;
@use "colors" as *;

.layout {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.spinner-container {
  height: 50vh;
}
