@use "colors" as *;

.pillBar {
  background-color: $color-porcelain;
  border-radius: 12px;
  display: flex;
  height: 48px;
  padding: 4px;
  position: relative;

  .option {
    background-color: transparent;
    border: none;
    border-radius: 18px;
    cursor: pointer;
    height: 40px;
    position: relative;
    width: 40px;

    .label {
      position: relative;
      z-index: 2;
    }
  }

  .pill {
    background-color: $color-white;
    border-radius: 8px;
    bottom: 0;
    box-shadow: 0 0 12px 8px rgb(65 67 80 / 8%);
    height: 40px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
  }
}
