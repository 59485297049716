@use "colors" as *;

.container {
  display: flex;
  flex-flow: column wrap;
  gap: 8px;
  min-width: 112px;
}

.stats {
  &__text--container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 100%;
  }

  &__line-chart--container {
    background-color: $color-neutral-3;
    border-radius: 2px;
    display: flex;
    height: 3px;
    overflow: hidden;
    width: 100%;
  }
}
