@use "colors" as *;

.header {
  color: $color-typo-secondary;
  font-size: .75rem;
  font-weight: 700;
  text-align: left;
  text-transform: uppercase;

  &__content {
    align-items: center;
    display: flex;
    height: 3.5rem;
    justify-content: flex-start;
    padding: .5rem;
  }

  &__icon {
    margin-left: .2rem;
    transition: transform .2s cubic-bezier(0, .7, .3, 1);

    &--rotated {
      transform: rotate(180deg);
    }

    &--hidden {
      visibility: hidden;
    }
  }

  &--sortable {
    cursor: pointer;
  }

  &--first {
    padding-left: 1.25rem;
  }

  &--last {
    padding-right: 1.25rem;
  }
}
