@use "mixins/hide" as *;
@use "mixins/breakpoints" as *;

.container {
  align-items: center;
  display: flex;
  justify-content: center;
}

.label {
  @include hide-visually;
}

.image {
  height: 32px;
}
