@use "colors" as *;

.result {
  $result: &;

  align-items: center;
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &--global {
    inset: 0;
    position: absolute;
    z-index: 6000;
  }

  &:not(#{$result}--global) {
    max-width: 41.25rem;
    padding: 3.75rem 7.5rem;
  }

  &__imageContainer {
    margin-bottom: 1.563rem;
  }

  &__description {
    margin-top: 2rem;
    max-width: 600px;
    padding: 0 1rem;
    text-align: center;
  }

  &__above-buttons-text {
    font-weight: 700;
    text-align: center;
  }

  &__illustration {
    height: 180px;
    margin: 0 auto;
    width: 242px;
  }

  &__with-background {
    background-color: $color-white;
  }

  &__after {
    padding: 1rem 0;
  }
}
