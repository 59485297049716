@use "colors" as *;

$background-color: #f8f9f9;

.content-placeholder {
  background-color: $background-color;
  border: 1px dashed $color-light-gray;
  border-radius: 20px;
  padding: 1rem;

  &__text {
    color: $color-nickel;
  }
}
