@use "colors" as *;

.container {
  background-color: $color-background-light;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  white-space: pre-wrap;
}
