@use "colors" as *;

.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.head {
  align-items: center;
  display: flex;
  gap: 8px;
}

.icon {
  color: $color-neutral-2;
  height: 13px;
  width: 13px;
}

.completion-rate {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}

.comment {
  font-size: .857rem;
}
