@use "colors" as *;
@use "mixins/reset" as *;

.operational-items-list {
  @include reset-list;

  display: flex;
  flex-direction: column;
  gap: 12px;

  &--container {
    display: flex;
    flex: 1 1 100%;
    flex-direction: column;
    height: max-content;
    overflow: hidden;
  }
}
