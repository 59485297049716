@use "fonts" as *;
@use "colors" as *;

html,
body {
  background-color: $color-white;
  color: $color-typo-primary;
  font-size: 14px;
}

* {
  font-family: $font-family-sofia-pro;
}

#root {
  height: 100vh;
  scrollbar-gutter: stable;
}
