@use "colors" as *;

.header {
  display: flex;
  gap: 20px;
  padding: 0 24px;
  width: 100%;

  & h3 {
    flex: 1 1 100%;
  }
}

.theme-section {
  display: flex;
  gap: 20px;
  padding-top: 12px;
}

.objective-section {
  border-bottom: 1px solid $color-stroke-light;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  margin: 0 24px;
  padding-bottom: 20px;
}
