@use "colors" as *;

.controls {
  gap: 8px;
  justify-content: flex-start;
  width: 100%;

  &__button {
    color: $color-typo-secondary !important;
    height: 40px !important;
    padding: 0 !important;
    transition: all .2s cubic-bezier(.445, .05, .55, .95) !important;
    width: 40px !important;

    &__active {
      border-color: $color-typo-primary !important;
      color: $color-typo-primary !important;
    }
  }
}
