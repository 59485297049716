.container {
  background-repeat: no-repeat;
  background-size: cover;

  &--hidden {
    visibility: hidden;
  }

  &--column-start-1-end-5-size-medium {
    background-image: url("./static/fixed-tile-background-start-1-end-5-medium.svg?inline");
    height: 102px;
    width: 484px;
  }

  &--column-start-3-end-1-size-medium {
    background-image: url("./static/fixed-tile-background-start-3-end-1-medium.svg?inline");
    height: 100px;
    width: 316px;
  }

  &--column-start-3-end-5-size-medium {
    background-image: url("./static/fixed-tile-background-start-3-end-5-medium.svg?inline");
    height: 100px;
    width: 192px;
  }

  &--column-start-4-end-5-size-medium {
    background-image: url("./static/fixed-tile-background-start-4-end-5-medium.svg?inline");
    height: 100px;
    width: 164px;
  }

  &--column-start-5-end-2-size-medium {
    background-image: url("./static/fixed-tile-background-start-5-end-2-medium.svg?inline");
    height: 98px;
    width: 316px;
  }

  &--column-start-5-end-3-size-medium {
    background-image: url("./static/fixed-tile-background-start-5-end-3-medium.svg?inline");
    height: 100px;
    width: 192px;
  }

  &--column-start-5-end-3-size-big {
    background-image: url("./static/fixed-tile-background-start-5-end-3-big.svg?inline");
    height: 160px;
    width: 301px;
  }

  &--column-start-5-end-6-size-medium {
    background-image: url("./static/fixed-tile-background-start-5-end-6-medium.svg?inline");
    height: 100px;
    width: 184px;
  }

  &--column-start-6-end-4-size-medium {
    background-image: url("./static/fixed-tile-background-start-6-end-4-medium.svg?inline");
    height: 100px;
    width: 324px;
  }
}
