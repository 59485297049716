@use "colors" as *;
@use "mixins/reset" as *;

.list-box-section {
  $menu-section: &;

  &__heading {
    color: $color-typo-secondary;
    font-size: .75rem;
    font-weight: 700;
    height: 1.25rem;
    padding: 1.25rem 1rem;
    text-transform: uppercase;
    user-select: none;
  }

  &__list {
    @include reset-list;

    margin-top: .5rem;
  }

  &:not(#{$menu-section}--first) {
    margin-top: .5rem;
  }
}
