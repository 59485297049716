@use "colors" as *;

.emoji-icon {
  align-items: center;
  background-color: $color-background-dark;
  display: flex;
  justify-content: center;

  &__medium {
    border-radius: 50%;
    font-size: 2.143rem;
    height: 2.33em;
    line-height: 2.857rem;
    width: 2.33em;
  }

  &__small {
    border-radius: 12px;
    font-size: 2.143rem;
    height: 1.6em;
    width: 1.6em;
  }

  &__tiny {
    border-radius: 12px;
    font-size: 2.143rem;
    height: 1.428em;
    width: 1.428em;
  }
}
