@use "colors" as *;

.heading {
  font-weight: 400;

  &--bold {
    font-weight: 700;
  }

  &--level-1 {
    font-size: 2.5rem;
    line-height: 3rem;
  }

  &--level-2 {
    font-size: 2rem;
    line-height: 2.75rem;
  }

  &--level-3 {
    font-size: 1.5rem;
    line-height: 2.125rem;
  }

  &--level-4 {
    font-size: 1.25rem;
    line-height: 2rem;
  }

  &--level-5 {
    font-size: 1.063rem;
    line-height: 1.5rem;
  }

  &--no-margin {
    margin: 0;
  }

  &--ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &--color-regular {
    color: $color-mine-shaft;
  }

  &--color-primary {
    color: $color-primary;
  }
}
