@use "colors" as *;

.link {
  align-items: center;
  color: $color-typo-primary;
  display: flex;
  gap: 8px;
  height: 100%;
  padding: 7.5px 8px;
  text-decoration: none;
  text-overflow: ellipsis;
  transition: all .2s cubic-bezier(0, .7, .3, 1);
  white-space: nowrap;
  width: fit-content;
}

.container {
  background-color: $color-white;
  border: 1px solid $color-stroke-medium;
  border-radius: 8px;
  overflow: hidden;
  transition: all .2s cubic-bezier(0, .7, .3, 1);
  width: fit-content;

  &:hover {
    background-color: $color-hover-light;

    & .link {
      text-decoration: underline;
    }
  }
}

.icon {
  color: $color-typo-primary;
  height: 12px;
  width: 12px;
}
