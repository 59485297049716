@use "colors" as *;
@use "sizes" as *;
@use "mixins/breakpoints" as *;

.container {
  align-items: flex-start;
  background-color: $color-white;
  display: flex;
  justify-content: center;
}

.inner-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
  min-width: 0;
  padding: 0 $phone-margin;

  @include tablet-viewport {
    padding: 0 $tablet-margin;
  }

  @include desktop-viewport {
    flex: none;
    padding: 0;
    width: $size-layout-content-max-width;
  }
}
