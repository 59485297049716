@use "colors" as *;

.container {
  display: flex;
  flex-flow: row nowrap;
  min-height: 10rem;
}

.heading {
  align-items: center;
  border-bottom: 1px solid $color-alto;
  display: flex;
  height: 4rem;
  justify-content: flex-start;
  padding-left: 1.25rem;
}

.groups {
  border-right: 1px solid $color-alto;
  width: 18rem;

  &__controls {
    padding: 1.25rem;
  }
}

.group {
  cursor: pointer;
  padding: 1.25rem 1.5rem !important; // stylelint-disable declaration-no-important
}

.items {
  flex: 1;

  &__heading {
    font-weight: 700;
  }

  &__controls {
    padding: 1.25rem;
  }

  &__message-container {
    padding: 1.25rem;
  }
}

.item {
  border-bottom: 1px solid $color-alto;
  padding: 1.25rem;
  position: relative;

  &__remove-button {
    position: absolute;
    right: .5rem;
    top: .5rem;
  }
}

.create-button {
  &:not(:first-of-type) {
    margin-top: 1rem;
  }
}

.group-modal {
  &__controls {
    justify-content: space-between;
    width: 100%;
  }
}
