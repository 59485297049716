@use "colors" as *;
@use "sizes" as *;

.container {
  margin-bottom: 1rem;
}

.heading {
  display: flex;
  justify-content: space-between;
  padding: 0 0 .75rem;

  .headingText {
    color: $color-typo-secondary;
    font-weight: 400;
  }
}

.title {
  display: flex;
  justify-content: space-between;
  padding: 0 0 .25rem;

  .titleText {
    color: $color-typo-primary;
    font-weight: 700;
  }
}

.desc {
  display: flex;
  justify-content: space-between;
  white-space: pre-line;

  .descText {
    color: $color-typo-secondary;
    font-weight: 400;
    white-space: pre-line;
  }
}

.list {
  margin: 0;
  margin-top: .75em;
}
