@use "mixins/reset" as *;
@use "colors" as *;

.button {
  @include reset-button;

  align-items: center;
  background-color: $color-white;
  border: 1px solid $color-silver;
  border-radius: .75em;
  cursor: pointer;
  display: flex;
  font-size: 1em;
  gap: .5em;
  justify-content: flex-start;
  padding: 1em .75em;
  transition: .2s background-color;
  width: 31em;

  &.buttonError {
    background-color: rgba($color-error, .1);
    border-color: $color-error;
  }

  &.buttonActive {
    background-color: rgba($color-anakiwa, .2);
    border-color: $color-primary;
  }
}

.colorName {
  color: $color-mine-shaft;
  font-size: 1em;
  font-weight: 700;
}

.description {
  color: $color-dove-gray;
  font-size: 1em;
}
