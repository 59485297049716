@use "colors" as *;

.card {
  padding-left: 0;
  padding-right: 0;
}

.chart {
  border: 1px solid $color-alto;
  border-radius: .5rem;
  margin-top: 1.5rem;
  padding: 2.5rem;
}

.insight-summary-container {
  margin-top: 1rem;
}
