@use "colors" as *;
@use "mixins/reset" as *;

.chip {
  $chip: &;

  background-color: $color-background-dark;
  border-radius: 6px;
  color: $color-black;
  cursor: pointer;
  font-size: .9rem;
  height: 2.25rem;
  padding: .5rem;
  user-select: none;
  white-space: nowrap;

  &__content {
    overflow: hidden;
    padding-bottom: .5rem;
    padding-left: .25rem;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__remove-button {
    @include reset-button;

    border-radius: 4px;
    color: $color-neutral-1;
    margin-left: .325rem;
    padding: 0 .25rem;
  }

  &__remove-icon {
    height: 1rem;
    margin-bottom: .2rem;
    width: 1rem;
  }

  &:hover {
    #{$chip}__remove-button {
      background-color: $color-hover-dark;
      color: $color-black;
    }
  }
}
