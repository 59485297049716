@use "colors" as *;

.theme-chip {
  background-color: $color-white;
  border-radius: .5rem;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.25rem;

  &__name {
    color: $color-mine-shaft;
  }
}
