@use "colors" as *;

.buttons {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 1rem;
  opacity: 0;
  transition: opacity .2s;

  &--always-show {
    opacity: 1;
  }
}

.container {
  padding: 0 8px;
  transition: background-color .2s;
}

.container-background {
  &:hover {
    background-color: $color-background-light;

    .buttons {
      opacity: 1;
    }
  }
}

.milestone-container {
  display: flex;
  flex-direction: row;
  gap: .5rem;
  min-height: 57px;
}

.content {
  align-items: center;
  display: flex;
  flex: 1;
}

.content-container {
  align-items: center;
  display: flex;
  gap: 20px;
}

.text-container {
  align-items: center;
  display: flex;
  gap: 8px;
}

.caption-container {
  align-items: center;
  display: flex;
  justify-content: flex-start;

  .icon {
    color: $color-boulder;
    height: 1rem;
    margin-right: .25rem;
    width: 1rem;
  }
}

.heading {
  font-size: 1rem;
  font-weight: 700;
  margin: 0;
}

.description {
  color: $color-typo-primary;
  margin: 0;
}

.children {
  align-items: center;
  display: flex;
}

.children-container {
  display: flex;
}
