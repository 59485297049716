@use "colors" as *;

.completion-rate {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  gap: 4px;
  width: 52px;

  &--icon {
    flex-basis: 16px;
    min-height: 16px;
    min-width: 16px;

    &__complete {
      color: $color-primary;
    }

    &__incomplete {
      color: $color-neutral-3;
    }
  }

  &--text {
    color: $color-typo-secondary;
    display: inline-block;
    flex-basis: 31px;
    text-align: right;
    white-space: nowrap;
  }
}
