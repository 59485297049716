@use "colors" as *;

.container {
  display: flex;
  gap: 12px;
  margin: 0;
  padding: 0;
}

.no-content {
  align-items: center;
  background-color: $color-white;
  border: 1px solid $color-stroke-medium;
  border-radius: 8px;
  color: $color-neutral-1;
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding: 7.5px 8px;
  transition: all .2s cubic-bezier(0, .7, .3, 1);
  width: fit-content;

  &:hover {
    background-color: $color-hover-light;
  }
}
