.milestone-status-drawer {
  &__form {
    margin-top: 28px;
  }
}

.form {
  margin-top: 14px;
}

.list {
  margin-block-end: 14px;
  margin-block-start: 14px;
}
