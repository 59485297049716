@use "colors" as *;

.steps-navigation {
  align-items: center;
  color: $color-silver;
  justify-content: center;

  &__separator {
    height: 1rem;
    width: 1rem;
  }
}
