@use "colors" as *;

.list-view-row-container {
  $list-view-row-container: &;

  margin: -1px 0 0 -1px;
  overflow: hidden;
  padding: 0 1.25rem;

  &__content {
    padding: .625rem 0;
  }

  &:not(:hover) {
    #{$list-view-row-container}__content {
      border-bottom: 2px solid transparent;
      border-top: 1px solid $color-stroke-light;
    }
  }

  &:hover {
    background-color: $color-hover-light;
    border-bottom: 1px solid transparent;
    border-top: 1px solid transparent;

    &:not(:first-of-type) {
      border-top: 1px solid $color-stroke-light;
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid $color-stroke-light;
    }

    #{$list-view-row-container}__content {
      margin-bottom: 1px;
    }
  }

  &--with-no-border-top {
    &:not(:hover) {
      #{$list-view-row-container}__content {
        border-top: 1px solid transparent;
      }
    }
  }
}
