@use "colors" as *;
@use "sass:color" as *;

.container {
  border: 1px solid $color-stroke-light;
  border-radius: 12px;
  margin-top: 30px;
}

.form {
  display: flex;
  gap: 40px;
  padding: 20px 20px 10px;
}
