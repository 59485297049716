.strategy-roadmap-chips {
  $strategy-roadmap-chips: &;

  align-items: flex-start;
  width: 100%;

  &__chip {
    #{$strategy-roadmap-chips}--direction-horizontal & {
      flex: 0;
    }
  }
}
