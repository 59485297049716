.content {
  align-items: center;
  justify-content: flex-start;
  max-width: 20rem;
  width: 100%;
}

.container {
  max-width: 20rem;
}
