@mixin reset-button {
  background: unset;
  border: unset;
  cursor: unset;

  &:focus {
    outline: unset;
  }

  &:active {
    color: unset;
  }
}

@mixin reset-input {
  background: unset;
  border: unset;

  &:focus {
    outline: unset;
  }
}

@mixin reset-anchor {
  color: unset;
  cursor: unset;
  text-decoration: none;

  &:focus {
    outline: none;
  }
}

@mixin reset-list {
  list-style-type: none;
  margin: 0;
  padding-left: 0;
}

@mixin reset-paragraph {
  margin: unset;
}
