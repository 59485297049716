@use "colors" as *;

.collapse {
  $collapse: &;

  border-radius: .75rem;

  &__header {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }

  &--background {
    background-color: $color-alabaster;

    #{$collapse}__content {
      padding: .5rem 2rem 2rem;
    }

    #{$collapse}__header {
      padding: .5rem .5rem .5rem 1rem;
    }
  }
}
