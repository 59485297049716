@use "mixins/reset" as *;
@use "colors" as *;

.theme-button {
  @include reset-button;

  align-items: center;
  border: 1px solid $color-stroke-medium;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  height: 4rem;
  justify-content: space-between;
  padding: 1.4rem;

  &__content {
    justify-content: space-between;
    width: 100%;
  }

  &--pressed {
    background-color: $color-background-light;
    border-color: $color-primary;
  }
}
