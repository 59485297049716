@use "colors" as *;
@use "sizes" as *;

.preview-strategy-modal-launch-bar {
  background-color: $color-background-light;
  display: flex;
  height: 2.75rem;

  &__content {
    display: flex;
    justify-content: space-between;
    max-width: $size-layout-content-max-width;
  }
}
