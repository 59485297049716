@use "colors" as *;
@use "sizes" as *;
@use "mixins/breakpoints" as *;

.container {
  $container: &;

  align-items: flex-start;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  padding: 20px 0;
  position: relative;

  .innerContainer {
    display: flex;
    flex: 1;
    gap: 20px;
    min-width: 0;
    padding: 0 $phone-margin;

    &--with-border {
      @include desktop-viewport {
        border: 1px solid $color-stroke-medium;
        border-radius: 16px;
        overflow: hidden;
      }
    }

    @include tablet-viewport {
      padding: 0 $tablet-margin;
    }

    @include desktop-viewport {
      flex: none;
      padding: 0;
      width: $size-layout-content-max-width;
    }
  }

  .content {
    flex: 1 1 796px;
    overflow: hidden;
  }

  .sider {
    background-color: $color-white;
    border-radius: 20px;
    flex: 1 1 384px;
    max-width: 384px;
  }

  &.contained {
    .content {
      border: 1px solid $color-stroke-medium;
      border-radius: 20px;
    }
  }

  &:not(#{$container}--with-border) {
    &.padding {
      .content {
        padding: 24px;
      }
    }
  }

  &--with-border.padding {
    .content {
      @include desktop-viewport {
        padding: 24px;
      }
    }
  }
}
