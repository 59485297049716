@use "sizes" as *;
@use "mixins/breakpoints" as *;

.container {
  display: flex;
  flex-direction: row;
}

.content {
  flex: 1 1;

  // TODO: Temporary margin for mobile devices
  margin: 20px 0;
  width: 0;

  @include desktop-viewport {
    margin: 20px 40px;
  }
}

.spacer {
  // TODO: Temporary display:none for mobile devices
  display: none;

  @include desktop-viewport {
    display: flex;
  }
}
