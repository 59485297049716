@use "colors" as *;

.header {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-top: 12px;
}

.description {
  color: $color-typo-secondary;
  margin: 0;
}
