.theme-mosaic {
  &__theme {
    margin-bottom: 1rem;
  }

  &__objectives {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}
