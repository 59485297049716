@use "colors" as *;

.team-card {
  background-color: $color-neutral-2;
  background-image: var(--bg-image, url("./static/background-people.svg"));
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  display: flex;
  height: 12.5rem;
  overflow: hidden;
  padding: 20px;
  position: relative;

  .top {
    align-self: flex-end;
    display: flex;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    width: 100%;
    z-index: 2;
  }

  &.masked {
    &::before {
      background: linear-gradient(360deg, #000 -16.75%, rgb(0 0 0 / 0%) 66.25%);
      bottom: 0;
      content: "";
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
    }
  }
}
