@use "colors" as *;

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
}

.description {
  color: $color-typo-secondary;
  margin: 0;
}
