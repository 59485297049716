@use "colors" as *;

.item {
  display: flex;
  flex-direction: column;
  gap: 11.5px;
  overflow: hidden;
  padding: 11.5px 0;
  position: relative;

  &::before {
    border-left: 1px solid $color-stroke-medium;
    content: "";
    display: block;
    height: 6px;
    left: 9.5px;
    position: absolute;
    top: 0;
    width: 1px;
  }

  &::after {
    border-left: 1px solid $color-stroke-medium;
    bottom: 0;
    content: "";
    display: block;
    height: 100%;
    left: 9.5px;
    position: absolute;
    top: 38px;
    width: 1px;
  }
}

.only-item {
  &::before {
    display: none;
  }
}

.last-row-item {
  &::after {
    display: none;
  }
}

.emphasis {
  color: $color-typo-primary;
}

.header {
  align-items: center;
  display: flex;
  gap: 16px;
}

.short-text {
  color: $color-typo-secondary;
}
