@use "colors" as *;

.list-box-row-container {
  $list-box-row-container: &;

  align-items: center;
  cursor: pointer;
  display: flex;
  gap: .25rem;
  justify-content: flex-start;
  outline: none; // remove react-aria outline
  padding: .625rem 1rem;
  user-select: none;

  &--focused {
    background-color: $color-hover-light;
  }

  &--disabled {
    cursor: default;
    opacity: .3;
  }

  &:hover {
    background-color: $color-hover-light;
  }

  &--selected {
    background-color: $color-primary;

    em {
      color: $color-white;
    }

    &:hover {
      background-color: $color-primary;

      em {
        color: $color-white;
      }
    }
  }

  &__checkbox {
    pointer-events: none;
  }
}
